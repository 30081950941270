module.exports = {
  siteTitle: 'Comercia Tu Producto', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#657db9',
  manifestThemeColor: '#657db9',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-identity/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Jesús Beltrán Barajas',
  heading: 'Director Comercial',
  text: 'Contáctame:',
  // social
  socialLinks: [
    {
      icon: 'fa-whatsapp',
      name: 'Celular',
      url:
        'https://wa.me/+5215514743215/?text=Hola Manuel, me interesa saber de tu oferta de productos',
    } /*
    {
      icon: 'fa-globe',
      name: 'WebPage',
      url: 'https://www.comerciatp.com/',
    },*/,
    {
      icon: 'fa-address-card',
      name: 'Tarjeta de direcciones',
      url:
        'https://res.cloudinary.com/farmazone/raw/upload/v1629526809/vcards/JesusBeltran.vcf',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:jesus.beltran@comerciatp.com',
    } /* 
    { 
      icon: 'fa-linkedin',
      name: 'linkedin',
      url: 'http://linkedin.com/in/abner-sánchez-fiz-a8018a105'
    },*/,
  ],
};
